.invalid-feedback {
    color: red;
}

.no-padding {
    padding: 0;
}

a{
    text-decoration: none;
}
.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: .5rem 0;
    margin: .125rem 0 0;
    font-size: .9rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;

    &.show {
        display: block;
    }

    .dropdown-item {
        display: block;
        width: 100%;
        padding: .25rem 1.5rem;
        clear: both;
        font-weight: 400;
        color: #212529;
        text-align: inherit;
        white-space: nowrap;
        background-color: transparent;
        border: 0;
    }
}

.file-input {
    left: 0;
    height: 100%;
    top: 0;
    opacity: 0;
    position: absolute;
    width: 100%;
}

.position-relative {
    position: relative;
}

.action-btn {
    display: inline-block;
    text-align: center;
    height: 40px;
    line-height: 40px;
}

.w-20 {
    width: 20%;
}
.w-30 {
    width: 30%;
}
.w-100 {
    width: 100%;
}

.rounded-circle {
    border-radius: 50%;
    border: 1px solid lightgray;
    border-width: 3px;
}

.banner-no-visible, .no-visible {
    opacity: 0.3;
}

.banner-alert {
    color: white;
    line-height: 60px;
    height: 60px;
    width: 100%;
    padding-left: 15px;
    border-radius: 10px;

    &.alert-success {
        background-color: $brand-primary;
    }
    &.alert-warning {
        background-color: #ff8a00;
    }
    &.alert-error {
        background-color: #db3d3f;
    }
}

.swal2-icon {
    font-size: 15px !important;
}

.ui-tooltip {
    max-width: 250px;
    height: auto;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    background: black;
    border: 1px solid #737373;
    color: white;
    //margin: 4px 121px 0 5px;
    padding: 20px;
    text-align: left !important;
    display: inline-block;
}

.ui-helper-hidden-accessible {
    display: none;
}

#content {
    margin-left: 20rem;
    width: 70%;

    @media (min-width: 1799px) {
        margin-left: 20rem;
        width: 60%;
        margin-right: 20rem;
    }
}

.main-content {
    margin: 0;
    width: 100%;

    @media (min-width: 1190px) {
        max-width: 900px;
    }
    @media (min-width: 1599px) {
        max-width: 1140px;
        width: auto;
    }
    @media (min-width: 1799px) {
        margin: 0 auto;
    }

}

.w-padding-lg-left {
    padding-left: 85px;
}

.img-chili {
    display: inline-block;
    width: 100px;
}

[type=radio] + img {
    cursor: pointer;
    margin-top: -12px;
    margin-left: 5px;
}

.wrap-post {
    box-shadow: 3px 5px 5px -4px black;
    border-radius: 15px;
    min-height: 230px;
    margin-top: 15px;
    margin-bottom: 45px;
    margin-left: 15px;
    width: 15%;
    border: 1px solid lightgray;
    text-align: center;
    padding-top: 15px;
    float: left;

    &.small {
        min-height: 150px;
    }

    .img-head {
        height: 50px;
        width: 100%;
    }

    img {
        display: inline-block;
        height: auto;
        min-height: 113px;
        width: auto;
        max-width: 60%;
    }
    label {
        display: block;
        margin-top: 10px;
    }
}

.postServices-list {

    .swal2-header {
        height: 40px;
    }
    .wrap-post {
        cursor: pointer;
        width: 30%;

        &.selected {

            label {
                color: $brand-primary;
                font-weight: bold;
            }
            .icon {
                color: $brand-secondary;
                opacity: 1;
            }
        }

        .icon {
            color: lightgray;
            font-size: 30px;
            position: relative;
            margin: 0 auto;
            display: inline-block;
            opacity: 0.8;
            top: 18px;
        }
    }
    .wrap-footer {
        height: 60px;
        width: 100%;
        position: absolute;
        bottom: -35px;
    }

}

.grid-img {
    position: absolute;
    right: 40%;
    top: 15px;

    label {
        color: lightgray;
    }
}

.badge-danger {
    display: block;
    color: red;
    font-weight: bold;
}

.formBox {

    label {
        color: gray !important;
    }
}

.swal2-confirm {
    color: $brand-primary !important;
}
