// Bootstrap
@import '~bootstrap/scss/bootstrap-grid';
@import "pluigns/lity.min.css";
@import "pluigns/sweetalert2.min.css";
@import "pluigns/nav.min.css";

// Variables
@import "../settings";
@import '../theme/theme';
@import 'login';
@import "nav";
@import "general";
@import "../form";
